import { Controller } from "stimulus"

let merch = {};
export default class extends Controller {
    static targets = [ 'expand', 'componentPath', 'payment', 'payload', 'saveCard' ]

    connect() {
        if (this.hasPayloadTarget) {
            const payload = this.payloadTarget.value;
            const saveCard = this.saveCardTarget.value;
            if (payload.length > 0) {
                renderPaymentComponent(payload, saveCard)
            } else {
                renderError();
            }
        }
        const checkoutBtn = document.querySelector('#payment-details-step .button');
        checkoutBtn.addEventListener('click', (event) => {
            event.preventDefault();
            if (validateTC()) {
                document.querySelector('.button.complete').nextElementSibling.classList.add('ajax-loader');
                document.querySelector('.button.complete').disabled = true;
                let totalAmount = document.querySelector('#total_amount');
                totalAmount = parseFloat(totalAmount.innerHTML.replace('$', ''))
                totalAmount > 0 ? submitCheckout() : continueCheckout();
            }
        });
    }

    toggle(event) {
        const parentTarget = event.target.closest(".checkout-step-body")
        parentTarget.querySelector('.tms').classList.toggle('visually-hidden')
        parentTarget.querySelector('.icon-plus').classList.toggle('visually-hidden')
        parentTarget.querySelector('.icon-minus').classList.toggle('visually-hidden')
    }
}

function validateTC() {
    const tcContainer = document.querySelector('#tc-agreement:checked');
    if (tcContainer === null) {
        const div = document.createElement('div');
        const agreement = document.querySelector('.required-agreement');
        const existingError = document.querySelector('.required-agreement .error');
        if (existingError !== null) {
            agreement.removeChild(existingError);
        }
        div.className = 'error';
        const error = 'Please read and agree to the Terms & Conditions';
        div.innerHTML = error;
        agreement.append(div);
        document.querySelector('.button.complete').nextElementSibling.classList.remove('ajax-loader');
        document.querySelector('.button.complete').disabled = false;
        return false
    } else {
        return true;
    }

}

function renderPaymentComponent(payload, saveCard) {
    const saveCardVal = saveCard !== 'false'
    const paymentComponent = document.querySelector('#payment-component');
    merch = new TessituraMerchantServices.PaymentComponent({
        "payload": payload,
        "showPayButton": false,
        "enableStoredCards": saveCardVal,
        "endpoints": {
            "authorize": "/payments/make",
            "finalize": "/payments/finalize"
        },
        "locale": "en-US",
    });
    paymentComponent.innerHTML = "";
    disableFormSubmit(paymentComponent);
    merch.mount(paymentComponent);
}

function submitCheckout() {
    const billingContainer = document.querySelector('#groups-billing-form');
    const billingForm = document.querySelector('#billing-shipping-form');
    const seamlessForm = document.querySelector('#seamless_user_form');
    document.querySelector('.button.complete').nextElementSibling.classList.add('ajax-loader');
    document.querySelector('.button.complete').disabled = true;
    if (billingContainer !== null) {
        submitForm(billingForm, billingForm.action)
            .then(response => response.status)
            .then(data => {
                submitPayment();
            })
    } else if (seamlessForm !== null) {
        submitForm(seamlessForm, seamlessForm.action)
            .then(response => response.status)
            .then(data => {
                merch.submit();
                setTimeout(onComplete, 5 * 1000)
            })
    } else {
        submitPayment();
    }

}


function submitPayment() {
    const paymentPlanForm = document.querySelector('#payments-form');
    submitForm(paymentPlanForm, '/payments/apply_payment_plan')
        .then(response => response.status)
        .then((data) => {
            merch.submit();
            setTimeout(onComplete, 5 * 1000)
        })
}

function onComplete() {
    sessionStorage.removeItem('itemsInCart');
    sessionStorage.removeItem('timeRemaining');
    document.querySelector('.button.complete').nextElementSibling.classList.remove('ajax-loader');
    document.querySelector('.button.complete').disabled = false;
}

async function submitForm(form, action) {
    try {
        const csrfToken = document.querySelector("[name='csrf-token']").content
        const path = action;
        const response = await fetch(path, {
            method: "POST",
            headers: {
                "X-CSRF-Token": csrfToken,
            },
            body: new FormData(form)
        });
        const json = await response;
        return json;

    } catch (error) {
        console.log('failed')

    }
}

function continueCheckout() {
    const paymentsForm = document.querySelector('#payments-form').action;
    const paymentComponent = document.querySelector('#payment-component');
    if (paymentComponent !== null) {
        paymentComponent.innerHTML = "";
    }
    fetch(paymentsForm, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then(response => response.json())
        .then(function (data) {
            sessionStorage.removeItem('itemsInCart');
            sessionStorage.removeItem('timeRemaining');
            window.location.replace(data.redirect);
        })
        .catch(function () {
            document.querySelector('.button.complete').nextElementSibling.classList.remove('ajax-loader')
            document.querySelector('.button.complete').disabled = false;
            console.log("error");
        });
}

function disableFormSubmit(container) {
    const myForm = container.closest('form');
    if (!myForm) {
        return;
    }
    myForm.addEventListener('submit', (e) => e.preventDefault());
    const componentReadyInterval = setInterval(() => {
        //wait for the drop-in to be ready
        const readySelector = '.adyen-checkout__dropin--ready';
        if (document.querySelector(readySelector)) {
            //remove default handling of button clicks within the drop-in
            for (const node of document.querySelectorAll(`${readySelector} button`)) {
                node.addEventListener('click', (e) => e.preventDefault());
            }
            clearInterval(componentReadyInterval);
        }
    }, 1);
}

function renderError() {
    const div = document.createElement('div');
    const paymentComponent = document.querySelector('#payment-component');
    div.className = 'error';
    const error = "We're sorry, our credit card provider is unavailable at this time. Please try again later. We apologize for the inconvenience.";
    div.innerHTML = error;
    paymentComponent.append(div);
}
